.wrapper {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 200px;

  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  visibility: hidden;
}

.wrapper[data-active="true"] {
  visibility: visible;
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close svg {
  fill: white;
  width: 1.1em;
  height: 1.1em;
}

@media (max-width: 450px) {
  .wrapper {
    max-width: 90%;
  }
}