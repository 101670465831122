.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  top: 70%;
  left: 52%;
  transform: translateY(-70%) translateX(-52%);
  /* right: 0;
  margin: auto; */
  width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 400px) {
  .content {
    width: 300px;
  }
}