
.wrapper {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  padding: 5px;
  box-sizing: border-box;
  width: 400px;
  color: black;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 600px) {
  .wrapper {
    max-width: 90%;
  }
}


.flyOutTitle {
  text-align: center;
  text-transform: uppercase;
}

.option label {
  font-size: 0.8em;
}

.option input[type="range"] {
  width: 100%;
}


.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioOption {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0px 6px;
}

.radioOption img {
  max-width: 100%;
}

.radioOption input {
  display: none;
}

.radioOption label {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: lightgray;
}
.radioOption label::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 75%;
  height: 75%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.radioOption input:checked+label {
  border: 1px solid black;
}
.radioOption input:checked+label::before {
  content: attr(data-text);
  position: absolute;
  bottom: -1.5em;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.7em;
  text-align: center;
}


.radioOption:first-child {
  margin: 0 6px 0 0;
}
.radioOption:last-child {
  margin: 0 0 0 6px;
}

.closeFlyout {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}