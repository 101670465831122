.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.0);

  opacity: 0;
  pointer-events: none;
  z-index: -9999;
  transition: opacity 300ms ease;
}

.modal[data-active="true"] {
  opacity: 1;
  pointer-events: all;
  z-index: 2;
  transition: opacity 300ms ease;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
  cursor: pointer;
}
.close:hover {
  color: var(--color-primary);
}
@media (max-width: 600px) {
  .close {
    top: 12px;
    right: 12px;
  }
}
.modal[data-role='admin'] .close {
  color: black;
}

.box {
  margin-top: 0px;
  position: relative;
  max-width: 700px;
  min-width: 400px;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;
  
  backdrop-filter: blur(8px);
  border-radius: 8px;
  color: black;
  background-color: rgba(255,255,255,0.5);

  max-height: 90vh;
  overflow-y: scroll;
}

.modal[data-mode="dark"] .box {
  color: white;
  background-color: rgba(0,0,0,0.5);
  box-shadow: 0px 0px 30px rgba(159, 106, 135, 0.5);
}
.modal[data-mode="dark"] .box svg{
  fill: white;
}

.modal[data-mode="dark-solid"] .box {
  color: white;
  background-color: rgba(0,0,0,1);
  box-shadow: 0px 0px 30px rgba(159, 106, 135, 0.5);
}
.modal[data-mode="dark-solid"] .box svg{
  fill: white;
}

.modal[data-size='small'] .box {
  max-width: 400px;
  min-width: auto;
}

.modal[data-size='med'] .box {
  max-width: 500px;
  min-width: auto;
}
.modal[data-size='auto'] .box {
  min-width: auto;
  max-width: auto;
  width: auto;
}

@media (max-width: 600px) {
  .box {
    /* max-width: 90%;
    min-width: auto;
    width: 90%; */
    
    width: 90%;
    max-width: 90%;
    min-width: 90%;
  }
}

.content {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* border: 1px solid white; */
  border-radius: 10px;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.modal[data-position="bottom"] .box {
  position: absolute;
  bottom: 60px;
}
@media (max-height: 673px) {
  .content {
    padding: 40px;
  }
}
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }
}

.content h1,
.content h2,
.content h3,
.content h4 {
  margin: 0;
}

.modal[data-role='admin'] .content,
.modal[data-role='admin'] .box {
  background-color: white;
}

.modal[data-role='admin'] .box {
  max-width: 300px;
  min-width: auto;
}