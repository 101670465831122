.navControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.1);
  backdrop-filter: blur(10px);
  cursor: pointer;
}
.navControl svg{
  fill: white;
  width: 2.2em;
  height: 2.2em;
  pointer-events: none;
}

.navControl[data-direction='left'] {
  right: 10px;
}
.navControl[data-direction='right'] {
  left: 10px;
}