.wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.preview video {
  min-width: 100%;
  height: 100%;
  /* object-fit: cover; */
  position: absolute;
  top: 50%;
  right: 50%;
  transform: rotateY(180deg) translateY(-50%) translateX(-50%);
  margin: auto;
}

.tempCanvas {
  display: none;
}

.preview[data-imagerendered="true"] video{
  opacity: 0;
}

.loader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.capture {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
}
.capture::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 90%;
  height: 90%;
  background-color: red;
}


.test {
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  border: 2px solid green;
}