.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .wrapper {
    font-size: 0.8em;
  }
}

.content {
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 350px;
}


.wrapper form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .title {
    margin-bottom: 15px;
  }
}

.titleMain {
  font-size: 2em;
}
.titleSub{
  font-size: 1em;
}

.confirmation {
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
}

.loader {
  width: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7em;
  gap: 10px;
}