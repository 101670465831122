.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://d1grxtbx9md071.cloudfront.net/images/background-img-1.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.wrapper[data-fade="true"] {
  opacity: 0;
  transition: opacity 500ms ease;
}

.bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  border: 2px solid white;
  width: 400px;
  padding: 4px;
  box-sizing: border-box;
  user-select: none;
}

@media (max-width: 800px) {
  .bar {
    width: 80%;
  }
}

.bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

.text {
  position: relative;
  color: white;
  mix-blend-mode: difference;
  font-size: 0.7em;
  letter-spacing: 2px;
}