:root {

}

.font-ernie {
  font-family: "adobe-handwriting-ernie", sans-serif;
font-weight: 400;
font-style: normal;
}

.font-frank {
  font-family: "adobe-handwriting-frank", sans-serif;
font-weight: 400;
font-style: normal;
}

body,html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  font-family: 'Times', serif;
  background-color: black;
  color: white;

  background-image: url('https://d1grxtbx9md071.cloudfront.net/images/background-img-1.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.grecaptcha-badge {
	display: none !important;
}

.screen {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  border: 4px solid blue;
}


.webgl-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.addpage {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
}

.btn {
  padding: 8px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  user-select: none;
}

.btn svg {
  pointer-events: none;
}

.btn--paper {
  background-color: transparent;
  background-image: url('https://d1grxtbx9md071.cloudfront.net/images/torn-paper-piece.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /* font-family: 'adobe-handwriting-ernie'; */
  font-family: 'adobe-handwriting-frank';
  font-size: 1.4em;
  padding-top: 15px;
  color: black;
}

.text-handwritting {
  font-family: 'adobe-handwriting-frank';
}



.test-add {
position: absolute;
top: 0;
left: 0;
}


label {
  display: block;
}

.form-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-row {
  text-align: left;
}

.input-row input{
  font-size: 1em;
  padding: 8px;
}

.spinner-small {
  width: 40px;
  height: 40px;
}


.patchbay-temp {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.patchbay-temp button{
  border-radius: 50px;
  outline: none;
  border: none;
  padding: 8px 16px;
  font-weight: 800;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 4px;
}
.patchbay-temp button svg{
  fill: white;
  width: 1em;
  height: 1em;
}
.btn-spotify {
  background-color: #1ED760;
}
.btn-apple {
  background-color: #FF2E56;
}
.btn-tidal {
  background-color: black;
}
.btn-amazon {
  background-color: #4004F1;
}