.controls {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.control {
  position: relative;
  cursor: pointer;
  width: 100%;
  /* flex: 1 1 auto; */
  
  border: none;
  outline: none;
  background-color: transparent;
  
  color: white;
  
  width: 50px;
  height: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;

  border-radius: 10px;
  padding: 0 !important;
}

.control svg {
  fill: white;
  width: 30px;
  height: 30px;
  margin: 0 !important;
  pointer-events: none;
}

.control[data-active="true"] {
  background-color: white;
  color: black;
}

.control[data-active="true"] svg {
  fill: black;
}



.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioOption {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0px 6px;
}

.radioOption img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.radioOption input {
  display: none;
}

.radioOption label {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background-color: lightgray;
}
.radioOption label::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 75%;
  height: 75%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.radioOption input:checked+label {
  border: 1px solid black;
}
.radioOption input:checked+label::before {
  content: attr(data-text);
  position: absolute;
  bottom: -1.5em;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 0.7em;
  text-align: center;
}


.radioOption:first-child {
  margin: 0 6px 0 0;
}
.radioOption:last-child {
  margin: 0 0 0 6px;
}

.closeFlyout {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.cta {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}