.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
}

.paint {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  /* width: 300px;
  height: 400px; */
  aspect-ratio: 3/4;
  max-height: 80%;
  margin: auto;
  box-sizing: border-box;
  background-color: black;
  background-size: cover;
}

@media (max-aspect-ratio: 3/4) {
  .paint {
    max-height: auto;
    max-width: 90%;
  }
}


.canvasWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: grey;
}


.canvasWrap canvas {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.close svg {
  fill: white;
  width: 35px;
  height: 35px;
}